<template>
    <div>
        <v-row class="mb-3">
            <!-- Sales Overview -->
            <v-col md="6" lg="6" v-for="item in dashboard_items" :key="item.name">
                <v-card class="pa-3" elevation="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="text-overline mb-2">
                                Sales Overview
                            </div>
                            <v-list-item-title class="headline font-weight-bold mb-1 deep-purple--text">
                                {{ formatAsCurrency('R', calcAnnualSales()) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <!-- Period Breakdown Headers -->
                    <v-list-item>
                        <v-list-item-content>
                            <v-row>
                                <v-col md="4" class="subtitle-2 font-weight-regular">Open Orders</v-col>
                                <v-col md="4" class="subtitle-2 font-weight-regular">Today</v-col>
                                <v-col md="4" class="subtitle-2 font-weight-regular">This Month</v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>

                    <!-- Period Breakdown Data -->
                    <v-list-item>
                        <v-list-item-content>
                            <v-row>
                                <v-col md="4" class="font-weight-bold mb-4">{{ openOrders() }}</v-col>
                                <v-col md="4" class="font-weight-bold mb-4">{{ formatAsCurrency('R', calcSalesToday()) }}</v-col>
                                <v-col md="4" class="font-weight-bold mb-4">{{ formatAsCurrency('R', calcMonthlySales()) }}</v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>

            <!-- Branch Performance -->
            <v-col md="6" lg="6">
                <v-card class="pa-3" elevation="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="text-overline mb-2">Sales per branch</div>
                        </v-list-item-content>
                    </v-list-item>

                    <!-- Branch Performance Headers -->
                    <v-list-item>
                        <v-list-item-content>
                            <v-row>
                                <v-col lg="4" md="4" class="subtitle-2 font-weight-regular">
                                    <h4>Branch Name</h4>
                                </v-col>
                                <v-col lg="4" md="4" class="subtitle-2 font-weight-regular">
                                    <h4>Orders this month</h4>
                                </v-col>
                                <v-col lg="4" md="4" class="subtitle-2 font-weight-regular">
                                    <h4>Sales Total</h4>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>

                    <!-- Branch Data Rows -->
                    <v-list-item v-for="branch in branchesWithSales" :key="branch.branchName">
                        <v-list-item-content>
                            <v-row>
                                <v-col lg="4" md="4" class="subtitle-2 font-weight-regular">{{ branch.branchName }}</v-col>
                                <v-col lg="4" md="4" class="subtitle-2 font-weight-regular">{{ branch.orderCount }}</v-col>
                                <v-col lg="4" md="4" class="subtitle-2 font-weight-regular">{{ formatAsCurrency('R', branch.monthSales) }}</v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import {formatAsCurrency} from '../../../composables/external'
import {mapState} from 'vuex'
import { filterSalesStatus, calcSalesTotal, calcSalesToday, calcMonthlySales, calcAnnualSales, openOrders } from '../data/external_sales_orders'

export default {
    name: 'SalesOrdersTableSummaryTiles',
    props: ['sales_orders', 'dashboard_items'],
    data(){
        return {
            
        }
    },
    computed:  mapState({
        filterSalesStatus,
        branchesWithSales() {
            const confirmedOrders = this.sales_orders.filter(status => status.order_status === 'Confirmed' || status.order_status === 'Completed');
            const monthOrders = confirmedOrders.filter(date => date.order_date.substring(0, 7) === new Date().toISOString().substring(0, 7));

            // Create a map to store branch information
            const branchMap = new Map();

            // Loop through each order and update the branch information in the map
            monthOrders.forEach(order => {
                const branchName = order.order_branch ? order.order_branch.branch_name : 'Unassigned Branch';
                const orderValue = parseFloat(order.order_value);

                if (branchMap.has(branchName)) {
                    // Update existing branch information
                    const branchInfo = branchMap.get(branchName);
                    branchInfo.orderCount += 1;
                    branchInfo.monthSales += orderValue;
                    branchMap.set(branchName, branchInfo);
                } else {
                    // Add new branch information
                    branchMap.set(branchName, { orderCount: 1, monthSales: orderValue });
                }
            });

            // Convert the map to an array of objects
            const branchesWithSales = Array.from(branchMap, ([branchName, branchInfo]) => ({
                branchName,
                ...branchInfo
            })).sort((a, b) => b.monthSales - a.monthSales);

            return branchesWithSales;
        }
    }),
    methods: {
        formatAsCurrency,
        calcSalesTotal,
        calcSalesToday,
        calcMonthlySales,
        calcAnnualSales,
        openOrders,
    }
}
</script>
